import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ProductSliderProductWrapper, ProductSliderProductWrapperMobile } from './ProductSliderProduct.styled';
import { useMediaQuery } from 'react-responsive';
import { GatsbyImage } from 'gatsby-plugin-image';

const ProductSliderProduct = ({ name, description, image, id }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const product = useRef();

  return (
    <>
      {!isTabletOrMobile && (
        <ProductSliderProductWrapper>
          <div className="product" ref={product}>
            <Row>
              <Col md={5}>
                <div className="text-content">
                  <div className="title">{name}</div>
                  <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </Col>
              <Col md={{ span: 6, offset: 1 }}>
                <div className="image">
                  <GatsbyImage
                    width={400}
                    image={image}
                    alt={name}
                    style={id === 1 || id === 4 || id === 5 ? { marginTop: '20px' } : ''}
                  />
                  {/* <img width="294" height="574" className="img-fluid" src={image} alt={name} /> */}
                </div>
              </Col>
            </Row>
          </div>
        </ProductSliderProductWrapper>
      )}
      {isTabletOrMobile && !isTablet && (
        <ProductSliderProductWrapperMobile>
          <div className="product" ref={product}>
            <Row>
              <Col>
                <div className="text-content">
                  <div className="title">{name}</div>
                  <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="image">
                  <GatsbyImage image={image} alt={name} />
                </div>
              </Col>
            </Row>
          </div>
        </ProductSliderProductWrapperMobile>
      )}
      {isTablet && (
        <ProductSliderProductWrapperMobile>
          <div className="product" ref={product}>
            <Row>
              <Col sm={6}>
                <div className="text-content">
                  <div className="title">{name}</div>
                  <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </Col>
              <Col sm={6}>
                <div className="image">
                  <GatsbyImage image={image} alt={name} />
                </div>
              </Col>
            </Row>
          </div>
        </ProductSliderProductWrapperMobile>
      )}
    </>
  );
};

export default ProductSliderProduct;
