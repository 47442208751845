import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  PartnersContent,
  PartnersWrapper,
  PartnersWrapperMobile,
  SinglePartnerWrapper,
  ImagesWrapper,
  SingleImageWrapper,
} from './Partners.styled';
import { Row, Col, Container } from 'react-bootstrap';
import Pattern from '../Pattern/Pattern';
import SinglePartner from './SinglePartner';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';

const ImagePartner = styled.div`
  margin-top: 2rem;
`;

const Partners = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  const data = useStaticQuery(graphql`
    query airportImages {
      airport1: file(relativePath: { eq: "airport1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 250, height: 188, placeholder: BLURRED, layout: FIXED)
        }
      }
      airport2: file(relativePath: { eq: "airport2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 141, height: 188, placeholder: BLURRED, layout: FIXED)
        }
      }
      airport3: file(relativePath: { eq: "airport3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 250, height: 188, placeholder: BLURRED, layout: FIXED)
        }
      }
      airport4: file(relativePath: { eq: "airport4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 250, height: 188, placeholder: BLURRED, layout: FIXED)
        }
      }
      airport5: file(relativePath: { eq: "airport5.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 250, height: 188, placeholder: BLURRED, layout: FIXED)
        }
      }
      airport2mobile: file(relativePath: { eq: "airport2mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 250, height: 350, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `);

  return (
    <>
      {!isTabletOrMobile && (
        <PartnersWrapper id="partners">
          <Pattern />
          <div className="contributors-filter-image">
            <StaticImage placeholder="blurred" src="../../images/contributors_filter_logo.png" alt="logo" />
          </div>
          <PartnersContent>
            <Container>
              <h2 className="text-center title main-title text-uppercase">{t('partners.title')}</h2>
              <Row>
                <Col className="text-center">
                  <ImagePartner>
                    <a href="http://www.adlerandgorda.ch/" target="_blank" rel="noreferrer">
                      <StaticImage placeholder="blurred" src="../../images/logo_ag_white.png" alt="Adler" />
                    </a>
                  </ImagePartner>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SinglePartnerWrapper>
                    <SinglePartner name="Weitnauer Distribution Ltd" url="https://www.weitnauer.ch/" />
                  </SinglePartnerWrapper>
                </Col>
                <Col>
                  <SinglePartnerWrapper>
                    <SinglePartner name="Overseas Distribution Company SA" url="https://www.overseas.be/" />
                  </SinglePartnerWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SinglePartnerWrapper>
                    <SinglePartner name="Tad Duty Free LLC Tajikistan, Dushanbe International Airport">
                      <ImagesWrapper>
                        <Row>
                          <Col>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport1.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport2.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport3.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport4.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport5.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                          </Col>
                        </Row>
                      </ImagesWrapper>
                    </SinglePartner>
                  </SinglePartnerWrapper>
                </Col>
              </Row>
            </Container>
          </PartnersContent>
        </PartnersWrapper>
      )}
      {isTabletOrMobile && (
        <PartnersWrapperMobile id="partners">
          <PartnersContent>
            <Container>
              <h2 className="text-center title main-title text-uppercase">{t('partners.title')}</h2>
              <Row>
                <Col className="text-center">
                  <ImagePartner>
                    <a href="http://www.adlerandgorda.ch/" target="_blank" rel="noreferrer">
                      <StaticImage placeholder="blurred" src="../../images/logo_ag_white.png" alt="Adler" />
                    </a>
                  </ImagePartner>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SinglePartnerWrapper>
                    <SinglePartner name="Weitnauer Distribution Ltd" url="https://www.weitnauer.ch/" />
                  </SinglePartnerWrapper>
                </Col>
                <Col>
                  <SinglePartnerWrapper>
                    <SinglePartner name="Overseas Distribution Company SA" url="https://www.overseas.be/" />
                  </SinglePartnerWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SinglePartnerWrapper>
                    <SinglePartner name="Tad Duty Free LLC Tajikistan, Dushanbe International Airport">
                      <ImagesWrapper>
                        <Row>
                          <Col>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport1.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage
                                image={data.airport2mobile.childImageSharp.gatsbyImageData}
                                alt={`Partner`}
                              />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport3.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport4.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                            <SingleImageWrapper>
                              <GatsbyImage image={data.airport5.childImageSharp.gatsbyImageData} alt={`Partner`} />
                            </SingleImageWrapper>
                          </Col>
                        </Row>
                      </ImagesWrapper>
                    </SinglePartner>
                  </SinglePartnerWrapper>
                </Col>
              </Row>
            </Container>
          </PartnersContent>
        </PartnersWrapperMobile>
      )}
    </>
  );
};

export default Partners;
