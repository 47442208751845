import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VideoWrapper } from './Video.styled';
import { Row, Col, Container } from 'react-bootstrap';
import { useInViewport } from 'react-in-viewport';
import { useMediaQuery } from 'react-responsive';

const Video = ({ videoUrl, videoUrlMobile, videoUrlWebm, poster }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const videoref = useRef();
  const forwardedRef = useRef();
  const { inViewport } = useInViewport(videoref);

  useEffect(() => {
    if (inViewport) {
      forwardedRef.current.play();
    } else {
      forwardedRef.current.pause();
    }
  });

  return (
    <VideoWrapper ref={videoref}>
      <Container fluid>
        <Row>
          <Col className="p-0">
            {!isTabletOrMobile && (
              <video poster={poster} preload="metadata" playsInline ref={forwardedRef} muted loop>
                <source src={videoUrlWebm} type="video/webm" />
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
            {isTabletOrMobile && (
              <video poster={poster} preload="metadata" playsInline ref={forwardedRef} muted loop>
                <source src={videoUrlMobile} type="video/mp4" />
              </video>
            )}
          </Col>
        </Row>
      </Container>
    </VideoWrapper>
  );
};

export default Video;

Video.propTypes = {
  videoUrl: PropTypes.string,
};
