import styled from 'styled-components';

export const HeroWrapper = styled.section`
  margin: 0 auto !important;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  video {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 1224px) {
      height: 100vh;
      width: auto;
    }
  }
  .opti-logo {
    margin-top: 150px;
    position: absolute;
    z-index: 2;
    @media screen and (max-width: 1224px) {
      padding: 0 1rem;
      .parallax-inner {
        justify-content: center !important;
      }
      svg {
        width: 100%;
      }
    }
  }
  .overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.304359243697479) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;
