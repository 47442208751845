import styled from 'styled-components';

export const ContributorModalWrapper = styled.div`
  .modal-body {
    padding: 2rem;
  }
  .close {
    cursor: pointer;
    font-size: 2rem;
    margin-top: -25px;
  }
  .divider {
    height: 2px;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.304359243697479) 0%, rgba(0, 100, 117, 1) 100%);
    width: 70%;
    margin-left: 1rem;
  }
  .modal-title {
    .main {
      font-family: ivypresto-display, serif;
      font-weight: 700;
      font-size: 1.8rem;
    }
    .sub {
      font-size: 1rem;
    }
  }
`;
