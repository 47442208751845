import React, { useState } from 'react';
import {
  ChartCarouselWrapper,
  LegendWrapper,
  ChartCarouselWrapperMobile,
  DownloadWrapper,
  Center,
  CenterMobile,
} from './ChartCarousel.styled';
import { Container, Row, Col } from 'react-bootstrap';
import loadable from '@loadable/component';
const Carousel = loadable(() => import('react-spring-3d-carousel-2'));
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc';
import { useMediaQuery } from 'react-responsive';
const Slider = loadable(() => import('react-slick'));
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ChartCarousel = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const [goToSlide, setGoToSlide] = useState(0);
  const downloadPdfUrl = 'https://optimum-filter.com/__00_downloads/Report%20on%20OF%20Development.pdf';

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 4000,
    lazyLoad: true,
  };

  const data = useStaticQuery(graphql`
    query desktopImageQuery {
      image1: file(relativePath: { eq: "01_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 648, height: 365, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      image2: file(relativePath: { eq: "02_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 648, height: 365, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      image3: file(relativePath: { eq: "03_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 648, height: 365, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      image4: file(relativePath: { eq: "04_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 648, height: 365, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      image5: file(relativePath: { eq: "05_desktop.png" }) {
        childImageSharp {
          gatsbyImageData(width: 648, height: 365, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      imageMobile1: file(relativePath: { eq: "01_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, placeholder: BLURRED)
        }
      }
      imageMobile2: file(relativePath: { eq: "02_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, placeholder: BLURRED)
        }
      }
      imageMobile3: file(relativePath: { eq: "03_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, placeholder: BLURRED)
        }
      }
      imageMobile4: file(relativePath: { eq: "04_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, placeholder: BLURRED)
        }
      }
      imageMobile5: file(relativePath: { eq: "05_mobile.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, placeholder: BLURRED)
        }
      }
    }
  `);

  const slides = [
    {
      key: 1,
      content: (
        <GatsbyImage imgClassName="chart-element" image={data.image1.childImageSharp.gatsbyImageData} alt={`Chart`} />
      ),
    },
    {
      key: 2,
      content: (
        <GatsbyImage imgClassName="chart-element" image={data.image2.childImageSharp.gatsbyImageData} alt={`Chart`} />
      ),
    },
    {
      key: 3,
      content: (
        <GatsbyImage imgClassName="chart-element" image={data.image3.childImageSharp.gatsbyImageData} alt={`Chart`} />
      ),
    },
    {
      key: 4,
      content: (
        <GatsbyImage imgClassName="chart-element" image={data.image4.childImageSharp.gatsbyImageData} alt={`Chart`} />
      ),
    },
    {
      key: 5,
      content: (
        <GatsbyImage imgClassName="chart-element" image={data.image5.childImageSharp.gatsbyImageData} alt={`Chart`} />
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => setGoToSlide(index) };
  });

  const chartsMobile = [
    {
      id: 0,
      image: data.imageMobile1.childImageSharp.gatsbyImageData,
    },
    {
      id: 1,
      image: data.imageMobile2.childImageSharp.gatsbyImageData,
    },
    {
      id: 2,
      image: data.imageMobile3.childImageSharp.gatsbyImageData,
    },
    {
      id: 3,
      image: data.imageMobile4.childImageSharp.gatsbyImageData,
    },
    {
      id: 4,
      image: data.imageMobile5.childImageSharp.gatsbyImageData,
    },
  ];

  const nextSlide = () => {
    setGoToSlide((prevState) => prevState + 1);
  };

  const prevSlide = () => {
    setGoToSlide((prevState) => prevState - 1);
  };

  return (
    <Container style={{ overflow: 'hidden' }}>
      {!isTabletOrMobile && (
        <>
          <Row>
            <Col>
              <Center>{t('chart.explanation')}</Center>
            </Col>
          </Row>
          <Row>
            <Col>
              <ChartCarouselWrapper>
                <Carousel slides={slides} goToSlide={goToSlide} offsetRadius={4} />
                <div className="arrows">
                  <div className="arrow arrow-left" onClick={prevSlide}>
                    <VscChevronLeft />
                  </div>
                  <div className="arrow arrow-right" onClick={nextSlide}>
                    <VscChevronRight />
                  </div>
                </div>
              </ChartCarouselWrapper>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          {isTabletOrMobile && (
            <>
              <Row>
                <Col>
                  <CenterMobile>{t('chart.explanation')}</CenterMobile>
                </Col>
              </Row>
              <Slider {...settings} className="chart-slider-mobile">
                {chartsMobile.map((slide, index) => (
                  <ChartCarouselWrapperMobile key={index}>
                    <GatsbyImage image={slide.image} alt={`Chart`} />
                  </ChartCarouselWrapperMobile>
                ))}
              </Slider>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <LegendWrapper>
            <div className="main-legend">
              <div className="legend-item">
                <span className="bold">ROS:</span> {t('chart.ros')}
              </div>
              <div className="legend-item">
                <span className="bold">KRC:</span> {t('chart.krc')}
              </div>
              <div className="legend-item">
                <span className="bold">COPD:</span> {t('chart.copd')}
              </div>
              <div className="legend-item">
                <span className="bold">IL-8:</span> {t('chart.il8')}
              </div>
            </div>
          </LegendWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <DownloadWrapper>
            <a href={downloadPdfUrl} target="_blank" rel="noreferrer">
              <button className="button-primary">{t('chart.download_title')}</button>
            </a>
          </DownloadWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default ChartCarousel;
