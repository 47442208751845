import styled from 'styled-components';

export const ProductSliderProductWrapper = styled.div`
  
  .product {
      padding: 30px;
      color: white;
      height: calc(var(--slider-height) + var(--slider-negative-margin));
      .text-content {
        margin-top: 150px;
        .title {
          font-family: ivypresto-display, serif;
          font-weight: 700;
          font-style: normal;
          text-transform: uppercase;
          letter-spacing: 5px;
          font-size: 42px;
          line-height: 44px;
          margin-bottom: 30px;
        }
        .description {
          text-transform: uppercase;
          font-size: 12px;
          line-height: 26px;
          font-weight: 100;
        }
      }
      .image {
        img {
          
        }
      }
    }
  }
`;

export const ProductSliderProductWrapperMobile = styled.div`
  width: 100%;
  margin-top: 3rem;
  .title {
    font-family: ivypresto-display, serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 42px;
    line-height: 44px;
    margin-bottom: 30px;
  }
  .description {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 26px;
    font-weight: 100;
  }
  .product {
    .image {
      max-height: 500px;
      img {
        margin: 0 auto;
        margin-top: 25px;
      }
    }
  }
`;
