import styled from 'styled-components';

export const ProductSliderWrapper = styled.section`
  //padding: 50px 0;
  height: var(--slider-height);
  position: relative;
  .arrows {
    cursor: pointer;
    .arrow {
      position: absolute;
      z-index: 2;
      font-size: 4rem;
      opacity: 0.6;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      transition: all 1s;
      &:hover {
        opacity: 1;
      }
      &.right-arrow {
        left: 83%;
        bottom: 36%;
      }
      &.left-arrow {
        bottom: 25%;
        left: 0;
      }
    }
  }
  .slider-bg__right {
    position: relative;
    padding: 30px 0;
    color: white;
    height: var(--slider-height);
    background: #004d7f;
    transition: all 1s;
    overflow: hidden;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
    .light-radial {
      width: 500px;
      height: 500px;
      opacity: 0.3;
      background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 65%);
      margin: 0 auto;
      top: 50%;
      margin-top: -250px;
      position: absolute;
      left: 50%;
      margin-left: -250px;
    }
    &--denim {
      background: #101940;
    }
    &--sky-blue {
      background: #0069b5;
    }
    &--blue {
      background: #00a8f3;
      /* background: radial-gradient(ellipse at center, #004d7f 0%, #020738 100%); */
    }
    &--silver {
      background: #a6a6aa;
    }
    &--capsule {
      background: #075f6f;
    }
    &--capsule-2 {
      background: #095f70;
    }
    &--lightblue {
      background: #90c9dd;
      /* background: radial-gradient(ellipse at center, #90c9dd 0%, #0185b6 100%); */
    }
    &--turquoise {
      background: #64c2c7;
      /* background: radial-gradient(ellipse at center, #64c2c7 0%, #027083 100%); */
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    40% {
      opacity: 0.3;
    }
    60% {
      opacity: 0.5;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }

  .left {
    overflow: hidden;
    position: relative;
    padding: 0;
    .product-description {
      .title {
        margin-top: 40px;
        font-size: 2.4rem;
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 2px;
          left: 0;
          background: var(--gold);
        }
      }
      .content {
        padding: 30px 60px 0 0;
        color: var(--text);
        line-height: 32px;
      }
    }
  }
  .right {
    width: 64%;
    overflow-x: hidden;
    overflow-y: visible;
    margin-top: calc(-1 * var(--slider-negative-margin));
    position: relative;
    transition: all 1s;
    height: calc(var(--slider-height) + var(--slider-negative-margin));
    right: -100px;
    .image {
    }
    .slick-slider {
      margin-left: 50px;
    }
    .slick-list {
      /* overflow: visible; */
      padding: 0 50px !important;
      overflow: hidden;
      &:after {
        content: '';
        height: 100%;
        width: 3px;
        background: pink;
      }
    }
    button[type='button'] {
      display: none !important;
    }
    .slick-dots {
      /* margin-bottom: 100px; */
      margin-left: 80px;
      margin-bottom: 50px;
      text-align: left;
      li {
        width: 20px;
        height: 20px;
        button {
          width: 20px;
          height: 20px;
          &:before {
            color: white;
            width: 20px;
            height: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export const ProductSliderRow = styled.div`
  position: absolute;
  width: 100%;
  height: var(--slider-height);
  .container {
    padding: 0;
  }
`;

export const ProductSliderRowMobile = styled.div`
  position: relative;
  margin: 2rem 0 0 0;
  width: 100%;
  overflow: hidden;
  .arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    font-size: 2rem;
    &-right: {
      right: 0;
    }
    &-left {
      left: 0;
    }
  }
  .arrow-right {
    right: 0;
  }
  .slick-dots {
    bottom: 15px;
    li {
      button {
        color: white;
        &:before {
          color: white !important;
        }
      }
    }
  }
  .product {
    padding: 1rem 3rem;
  }
  .main-title {
    font-family: ivypresto-display, serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 1.875rem;
    margin-bottom: 2rem;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      margin-left: 15px;
      height: 2px;
      left: 0;
      background: var(--gold);
    }
  }
  .slider-bg {
    color: white;
    transition: all 1s;
    background: #004d7f;
    margin-top: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
    .light-radial {
      width: 300px;
      height: 300px;
      opacity: 0.3;
      background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 65%);
      margin: 0 auto;
      top: 80%;
      margin-top: -150px;
      position: absolute;
      left: 50%;
      margin-left: -150px;
    }
    &--denim {
      background: #101940;
    }
    &--sky-blue {
      background: #0069b5;
    }
    &--blue {
      background: #00a8f3;
      /* background: radial-gradient(ellipse at center, #004d7f 0%, #020738 100%); */
    }
    &--silver {
      background: #a6a6aa;
    }
    &--capsule {
      background: #075f6f;
    }
    &--capsule-2 {
      background: #095f70;
    }
    &--lightblue {
      background: #90c9dd;
      /* background: radial-gradient(ellipse at center, #90c9dd 0%, #0185b6 100%); */
    }
    &--turquoise {
      background: #64c2c7;
      /* background: radial-gradient(ellipse at center, #64c2c7 0%, #027083 100%); */
    }
  }
`;
