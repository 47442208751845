import styled from 'styled-components';

export const ContributorsWrapper = styled.section`
  background: rgb(0, 105, 123);
  background: radial-gradient(circle, rgba(0, 105, 123, 1) 0%, rgba(2, 87, 103, 1) 100%);
  padding: 8rem 2rem 2rem 2rem;
  position: relative;
  overflow: hidden;
  .arrow {
    cursor: pointer;
    position: absolute;
    color: white;
    font-size: 4rem;
    top: 50%;
    opacity: 0.7;
    transition: all 1s;
    &-right {
      right: -5rem;
    }
    &-left {
      left: -5rem;
    }
    &:hover {
      opacity: 1;
    }
  }
  .contributors-filter-image {
    position: absolute;
    top: 0;
    width: 200px;
    left: 50%;
    margin-left: -100px;
    img {
      width: 100%;
    }
  }
  .title {
    color: white;
    &:after {
      content: '';
      display: block;
      width: 20rem;
      margin: 0 auto;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 2px;
      background: var(--gold);
    }
  }
  .description {
    color: white;
    text-align: center;
    padding: 2rem 15rem;
  }
  .download-wrapper {
    margin: 4rem 0 0 0;
    p {
      color: white;
    }
    button {
      margin: 1rem;
    }
  }
`;

export const ContributorsContent = styled.div`
  position: relative;
  z-index: 1;
`;

export const ContributorsWrapperMobile = styled.div`
  background: rgb(0, 105, 123);
  background: radial-gradient(circle, rgba(0, 105, 123, 1) 0%, rgba(2, 87, 103, 1) 100%);
  padding: 4rem 0;
  margin-top: 2rem;
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    padding-top: 2rem;
    li {
      width: 15px;
      height: 15px;
      button {
        width: 15px;
        height: 15px;
      }
    }
  }
  .title {
    color: white;
    &:after {
      content: '';
      display: block;
      width: auto;
      margin: 0 auto;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 2px;
      background: var(--gold);
    }
  }
  .description {
    color: white;
    text-align: center;
    font-size: 0.875rem;
  }
  .download-wrapper {
    margin-top: 4rem;
    color: white;
  }
`;
