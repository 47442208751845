import React from 'react';
import { PatternWrapper } from './Pattern.styled';
import { StaticImage } from 'gatsby-plugin-image';

const Pattern = ({ showPatterns }) => {
  if (showPatterns === 2) {
    return (
      <PatternWrapper className="slider-pattern">
        <div className="pattern pattern-right pattern-right--top">
          <StaticImage
            src="../../images/contributors_pattern-topright.png"
            alt="Background"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
        <div className="pattern pattern-left pattern-left--bottom">
          <StaticImage
            src="../../images/contributors_pattern-botleft.png"
            alt="Background"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      </PatternWrapper>
    );
  } else {
    return (
      <PatternWrapper>
        <div className="pattern pattern-left pattern-left--top">
          <StaticImage
            src="../../images/contributors_pattern-topleft.png"
            alt="Background"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
        <div className="pattern pattern-right pattern-right--top">
          <StaticImage
            src="../../images/contributors_pattern-topright.png"
            alt="Background"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
        <div className="pattern pattern-left pattern-left--bottom">
          <StaticImage
            src="../../images/contributors_pattern-botleft.png"
            alt="Background"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
        <div className="pattern pattern-right pattern-right--bottom">
          <StaticImage
            src="../../images/contributors_pattern-botright.png"
            alt="Background"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      </PatternWrapper>
    );
  }
};

export default Pattern;
