import React from 'react';
import PropTypes from 'prop-types';
import { Name, Link } from './Partners.styled';

const SinglePartner = ({ name, url, children }) => {
  return (
    <>
      <Name>{name}</Name>
      <Link href={url} target="_blank" external>
        {url}
      </Link>
      {children && <div>{children}</div>}
    </>
  );
};

export default SinglePartner;

SinglePartner.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.node,
};
