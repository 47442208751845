import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';
import HomeBoxesSection from '../components/HomeBoxesSection/HomeBoxesSection';
import Contributors from '../components/Contributors/Contributors';
import ProductSlider from '../components/ProductSlider/ProductSlider';
import Video from '../components/Video/Video';
import ChartCarousel from '../components/ChartCarousel/ChartCarousel';
import Partners from '../components/Partners/Partners';
import AgeVerification from '../components/AgeVerification/AgeVerification';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive';

import AnimVideo from '../../static/21-9 v3.mp4';
// import AnimVideoWebm from '../../static/21-9 v3.webm';
import AnimPreload from '../images/anim_preload.webp';
import AnimVideoMobile from '../../static/1-1 v3.mp4';

const IndexPage = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const [showAgeVerification, setShowAgeVerification] = useState(true);
  const [cookies, setCookie] = useCookies(['verified']);

  const handleAgeVerification = () => {
    setCookie('verified', true, { path: '/', expires: 0 });
    setShowAgeVerification(false);
  };

  const handleAgeVerificationDeclined = () => {
    window.location.href = 'https://google.com';
  };

  return (
    <Layout>
      <ParallaxProvider>
        <CookiesProvider>
          <div className={`${showAgeVerification ? 'd-block' : ''}`}>
            {showAgeVerification && !cookies.verified && (
              <AgeVerification
                id="ageVerification"
                handleAgeVerification={handleAgeVerification}
                handleAgeVerificationDeclined={handleAgeVerificationDeclined}
              />
            )}
          </div>
        </CookiesProvider>
        <Hero />
        <HomeBoxesSection sections={data} />
        <Video videoUrlMobile={AnimVideoMobile} poster={AnimPreload} videoUrl={AnimVideo} />

        <ChartCarousel />
        <Contributors data={data} />
        <ProductSlider data={data} />
        <Partners />
      </ParallaxProvider>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
