import React, { useState, useEffect, useRef } from 'react';
import { ContributorsWrapper, ContributorsContent, ContributorsWrapperMobile } from './Contributors.styled';
import { Row, Col, Container } from 'react-bootstrap';
import ContributorCard from '../ContributorCard/ContributorCard';
import Pattern from '../Pattern/Pattern';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useInViewport } from 'react-in-viewport';
import { useMediaQuery } from 'react-responsive';
import ContributorModal from '../ContibutorModal/ContributorModal';
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

import contributorsData from '../../content/contributors.json';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrow arrow-right">
      <VscChevronRight onClick={onClick} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrow arrow-left">
      <VscChevronLeft onClick={onClick} />
    </div>
  );
};

const Contributors = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [personData, setPersonData] = useState({});
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const contributors = useRef();
  const { inViewport } = useInViewport(contributors);
  const downloadPdfUrl = 'https://optimum-filter.com/__00_downloads/Report%20on%20OF%20Development.pdf';

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    lazyLoad: true,
    beforeChange: (current, next) => setIndex(next),
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 4000,
    lazyLoad: true,
    beforeChange: (current, next) => setIndex(next),
    // adaptiveHeight: true,
  };

  const handleModal = (person) => {
    setShowModal(true);
    setPersonData(person);
  };

  useEffect(() => {
    if (inViewport) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  });

  const data = useStaticQuery(graphql`
    query logoQuery {
      pecs: file(relativePath: { eq: "university_pecs.png" }) {
        childImageSharp {
          gatsbyImageData(width: 144, height: 144, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      budapest: file(relativePath: { eq: "university_budapest.png" }) {
        childImageSharp {
          gatsbyImageData(width: 416, height: 117, placeholder: BLURRED)
        }
      }
      humeltis: file(relativePath: { eq: "humeltis.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <>
      <ContributorModal person={personData} isModalShow={showModal} onHideModal={setShowModal} />
      {!isTabletOrMobile && (
        <ContributorsWrapper id="contributors">
          <Pattern />
          <div className="contributors-filter-image">
            <StaticImage placeholder="blurred" src="../../images/contributors_filter_logo.png" alt="logo" />
          </div>
          <ContributorsContent ref={contributors}>
            <Container>
              <h2 className="text-center title main-title text-uppercase">{t('contributors.title')}</h2>
              <Row>
                <Col>
                  <p className="description">{index === 0 ? t('contributors.subtitle_persons') : ''}</p>
                </Col>
              </Row>

              <Slider {...settings}>
                <div>
                  <Row>
                    {contributorsData.featuredPersons.map((person, index) => (
                      <Col
                        md={4}
                        key={index}
                        onClick={() => handleModal(person)}
                        className={index === 0 ? 'offset-md-2' : ''}
                      >
                        <ContributorCard
                          longDesc={person.longDesc}
                          isPerson
                          title={person.name}
                          desc={person.desc}
                          isFeatured
                          index={index}
                        />
                      </Col>
                    ))}
                  </Row>

                  <Row>
                    {contributorsData.persons.map((person) => (
                      <Col key={person.name} md={4} onClick={() => handleModal(person)}>
                        <ContributorCard longDesc={person.longDesc} isPerson title={person.name} desc={person.desc} />
                      </Col>
                    ))}
                  </Row>
                </div>
                <div>
                  <Row>
                    {contributorsData.universities.map((university) => (
                      <Col key={university.name} md={6}>
                        <ContributorCard
                          desc={university.name}
                          image={
                            university.image === 'pecs'
                              ? data.pecs.childImageSharp.gatsbyImageData
                              : data.budapest.childImageSharp.gatsbyImageData
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    {contributorsData.companies.map((company) => (
                      <Col key={company.name} md={{ span: 8, offset: 2 }}>
                        <ContributorCard
                          className="contributor-company"
                          desc={company.name}
                          image={data.humeltis.childImageSharp.gatsbyImageData}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Slider>

              <div className="download-wrapper text-center">
                <p>{t('contributors.download_description')}</p>
                <a href={downloadPdfUrl} target="_blank" rel="noreferrer">
                  <button className="button-primary button-primary--white">{t('contributors.download')}</button>
                </a>
              </div>
            </Container>
          </ContributorsContent>
        </ContributorsWrapper>
      )}
      {isTabletOrMobile && (
        <ContributorsWrapperMobile id="contributors">
          <Container>
            <h2 className="text-center title main-title text-uppercase">{t('contributors.title')}</h2>
            <Row>
              <Col>
                <p className="description">
                  {index === 0 ? t('contributors.subtitle_persons') : ''}
                  {index === 1 ? t('contributors.subtitle_universities') : ''}
                </p>
              </Col>
            </Row>
            <Slider {...settingsMobile}>
              <div className="slide">
                <Row>
                  {contributorsData.featuredPersons.map((person) => (
                    <Col onClick={() => handleModal(person)} key={person.name} className="col-12">
                      <ContributorCard
                        longDesc={person.longDesc}
                        isPerson
                        title={person.name}
                        desc={person.desc}
                        className="featured-person"
                        isFeatured={true}
                      />
                    </Col>
                  ))}
                </Row>
                <Row id="contributors">
                  {contributorsData.persons.map((person) => (
                    <Col onClick={() => handleModal(person)} key={person.name} className="col-6">
                      <ContributorCard longDesc={person.longDesc} isPerson title={person.name} desc={person.desc} />
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="slide" style={{ height: '400px' }}>
                <Row>
                  {contributorsData.universities.map((university) => (
                    <Col key={university.name} md={6}>
                      <ContributorCard
                        desc={university.name}
                        image={
                          university.image === 'pecs'
                            ? data.pecs.childImageSharp.gatsbyImageData
                            : data.budapest.childImageSharp.gatsbyImageData
                        }
                      />
                    </Col>
                  ))}
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                  {contributorsData.companies.map((company) => (
                    <Col key={company.name} md={{ span: 8, offset: 2 }}>
                      <ContributorCard desc={company.name} image={data.humeltis.childImageSharp.gatsbyImageData} />
                    </Col>
                  ))}
                </Row>
              </div>
            </Slider>
            <div className="download-wrapper text-center">
              <p>{t('contributors.download_description')}</p>
              <a href={downloadPdfUrl} target="_blank" rel="noreferrer">
                <button className="button-primary button-primary--white">{t('contributors.download')}</button>
              </a>
            </div>
          </Container>
        </ContributorsWrapperMobile>
      )}
    </>
  );
};

export default Contributors;
