import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ContributorPersonWrapper } from './ContributorCard.styled';
import { useMediaQuery } from 'react-responsive';
import { GatsbyImage } from 'gatsby-plugin-image';

const ContributorCard = ({ title, desc, image, isPerson, isFeatured, isDeceased, longDesc, index }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  let conditionalClasses = classNames({
    'featured-person': isFeatured,
    'deceased-person': isDeceased,
  });

  return (
    <ContributorPersonWrapper className={isFeatured ? 'featured-person' : ''}>
      <div className={isPerson && !isTabletOrMobile ? 'flip-card' : ''}>
        <div className={isPerson && !isTabletOrMobile ? 'flip-card-inner' : ''}>
          <div className={`${isPerson && !isTabletOrMobile ? 'flip-card-front' : ''} ${conditionalClasses}`}>
            <div className="content">
              {isPerson ? (
                <h2 className="main-title" dangerouslySetInnerHTML={{ __html: title }}></h2>
              ) : (
                <GatsbyImage image={image} alt={`Contributor`} />
              )}
              <h3 className="desc">{desc}</h3>
            </div>
          </div>
        </div>
      </div>
    </ContributorPersonWrapper>
  );
};

export default ContributorCard;

ContributorCard.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  longDesc: PropTypes.string,

  isPerson: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isDeceased: PropTypes.bool,
  index: PropTypes.number,
};
