import styled from 'styled-components';

export const VideoWrapper = styled.section`
  @media screen and (max-width: 1224px) {
    margin: 0;
  }

  .title {
    font-size: 2.4rem;
    padding: 2rem 0 0 0;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      left: 0;
      background: var(--gold);
    }
  }
  video {
    margin: 0 auto;
    width: 100%;
  }
`;
