import styled from 'styled-components';

export const ChartCarouselWrapper = styled.div`
  height: 400px;
  .css-1fzpoyk {
    width: 50%;
  }
  @media screen and (min-width: 1600px) {
    height: 550px;
  }
  img {
    /* box-shadow: -1px -1px 142px -40px rgba(0, 0, 0, 0.14); */
  }
  .arrows {
    text-align: center;
    .arrow {
      display: inline-block;
      margin: 0 2rem;
      font-size: 2rem;
      opacity: 0.6;
      transition: all 1s;
      position: relative;
      z-index: 2;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
`;

export const LegendWrapper = styled.div`
  margin-top: 5rem;
  .main-legend {
    font-size: 0.875rem;
    text-align: center;
    .bold {
      font-weight: bold;
      color: var(--highlight);
    }
    .legend-item {
      display: inline-block;
      margin: 0 1rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .main-legend {
      text-align: left;
      .legend-item {
        display: block;
      }
    }
  }
`;

export const ChartCarouselWrapperMobile = styled.div`
  margin: 4rem 0;

  img {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;
  }
`;

export const SliderChartItemMobile = styled.div`
  img {
    width: 100%;
  }
`;

export const DownloadWrapper = styled.div`
  text-align: center;
  margin: 4rem 0 0 0;
  @media screen and (max-width: 1023px) {
    button {
      font-size: 1rem;
      padding: 10px 15px !important;
    }
  }
`;

export const Center = styled.div`
  text-align: center;
`;

export const CenterMobile = styled.div`
  text-align: center;
  margin-top: 1rem;
`;
