import React from 'react';
import PropTypes from 'prop-types';
import { HomeBoxContent, HomeBoxWrapper } from './HomeBox.styled';
import { Row, Col } from 'react-bootstrap';
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useMediaQuery } from 'react-responsive';
// import { Link } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';

const HomeBox = ({ section }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  return (
    <>
      <HomeBoxWrapper id="about-us">
        <Row className="d-flex align-items-center">
          <Col sm={12} md={6} className="p-lg-0">
            <HomeBoxContent>
              <h3 className="title main-title tex-uppercase main-title--blue">{section.title}</h3>
              <div className="content" dangerouslySetInnerHTML={{ __html: section.content }} />
              <div className="button">
                {!isTabletOrMobile && (
                  <>
                    <AnchorLink offset="100" href="#contributors">
                      <button className="button-primary">{section.button.text}</button>
                    </AnchorLink>
                    <Link to="/about-the-company">
                      <button className="button-primary">{section.button.text_about}</button>
                    </Link>
                  </>
                )}
                {isTabletOrMobile && (
                  <>
                    <a href="#contributors">
                      <button className="button-primary button-mobile">{section.button.text}</button>
                    </a>
                    <Link to="/about-the-company">
                      <button className="button-primary button-mobile">{section.button.text_about}</button>
                    </Link>
                  </>
                )}
              </div>
            </HomeBoxContent>
          </Col>
          <Col sm={12} md={6} className="p-lg-0 d-none d-md-block">
            <div className="image">
              <StaticImage
                placeholder="blurred"
                src="../../images/irewolede-PvwdlXqo85k-unsplash.png"
                alt="Abbout us"
              />
            </div>
          </Col>
        </Row>
      </HomeBoxWrapper>
    </>
  );
};

export default HomeBox;

HomeBox.propTypes = {
  section: PropTypes.object,
};
