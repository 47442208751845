import styled from 'styled-components';

export const HomeBoxWrapper = styled.div`
  margin: 10rem 0;
  position: relative;
  //overflow: hidden;
  padding: 1rem 0 1rem 1rem;
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    padding: 0;
    margin: 5rem 0;
  }
  .order-md-first {
    .content,
    .title,
    .button {
      padding-right: 4.375rem;
      @media screen and (max-width: 1023px) {
        padding: 0;
        text-align: left;
      }
    }
  }
  .order-md-last {
    .content,
    .title,
    .button {
      padding-left: 4.375rem;
    }
  }
  .image {
    margin-top: 8rem;
    img {
      box-shadow: -1px -1px 78px -27px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const HomeBoxContent = styled.div`
  .button {
    button {
      margin: 0 5px;
      font-size: 1rem;
      @media screen and (max-width: 1023px) {
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    img {
      margin: 1rem 0;
    }
    .button {
      button {
        width: 100%;
        margin: 1rem 0;
      }
    }
  }
  @media screen and (min-width: 1023px) and (max-width: 1199px) {
    .button {
      button {
        width: 100%;
        margin: 1rem 0;
      }
    }
  }
  .title {
    font-size: 2.4rem;
    padding: 2rem 0 0 0;
    @media screen and (max-width: 1023px) {
      font-size: 1.875rem;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      left: 0;
      background: var(--gold);
    }
  }
  .content {
    font-size: 1.2rem;
    line-height: 2rem;
    margin: 2rem 0;
    padding: 0 4rem 0 0;
    text-align: justify;
    @media screen and (max-width: 1023px) {
      padding: 0;
    }
    .featured {
      color: var(--highlight);
      font-weight: bold;
    }
  }
`;
