import React, { useRef, useEffect, useState } from 'react';
import { HomeBoxesSectionWrapper } from './HomeBoxesSection.styled';
import { Container } from 'react-bootstrap';
import HomeBox from '../HomeBox/HomeBox';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useInViewport } from 'react-in-viewport';

const HomeBoxesSection = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const homebox = useRef();
  const { inViewport } = useInViewport(homebox);

  useEffect(() => {
    if (inViewport) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  });

  return (
    <Container>
      <div ref={homebox}>
        <HomeBox
          section={{
            title: t('sections.section_1.title'),
            content: t('sections.section_1.description'),
            button: {
              text: t('sections.section_1.button'),
              link: '#',
              text_about: t('sections.section_1.button_about'),
            },
            image: '../../../static/box.png',
            imageAlignment: 'right',
          }}
        />
      </div>
    </Container>
  );
};

export default HomeBoxesSection;
