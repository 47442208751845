import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'react-bootstrap';
import { ContributorModalWrapper } from './ComtributorModal.styled';
import { VscChromeClose } from 'react-icons/vsc';

const ContributorModal = ({ isModalShow, person, onHideModal }) => {
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => {
    setModalShow(false);
    onHideModal(false);
  };

  useEffect(() => {
    setModalShow(isModalShow);
  }, [isModalShow]);

  return (
    <Modal show={modalShow} size="lg" centered onHide={() => handleClose()}>
      <ContributorModalWrapper>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="main" dangerouslySetInnerHTML={{ __html: person.name }} />
            <div className="sub">{person.desc}</div>
          </Modal.Title>
          <div className="close" onClick={handleClose}>
            <VscChromeClose />
          </div>
        </Modal.Header>
        <div className="divider"></div>
        <Modal.Body>
          <Row>
            <Col>{person.longDesc}</Col>
          </Row>
        </Modal.Body>
      </ContributorModalWrapper>
    </Modal>
  );
};

export default ContributorModal;

ContributorModal.propTypes = {
  isModalShow: PropTypes.bool,
};
