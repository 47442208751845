import React from 'react';
import { HeroWrapper } from './Hero.styled';
import VideoBg from '../../../static/video_loop_2.mp4';
import VideoWebm from '../../../static/video_loop.webm';
import VideoPreload from '../../images/video_loop.webp';
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from 'gatsby-plugin-image';

const Hero = () => {
  return (
    <HeroWrapper>
      <div className="opti-logo">
        <Parallax className="custom-class" y={[-100, 100]} tagOuter="figure">
          <StaticImage placeholder="blurred" src="../../images/OF_white_gold.png" alt="Optimum-Filter" />
        </Parallax>
      </div>
      <div className="overlay"></div>
      <video poster={VideoPreload} playsInline muted autoPlay loop>
        {/* <source src={VideoWebm} type="video/webm" /> */}
        <source src={VideoBg} type="video/mp4" />
      </video>
    </HeroWrapper>
  );
};

export default Hero;
