import React, { useState, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ProductSliderWrapper, ProductSliderRow, ProductSliderRowMobile } from './ProductSlider.styled';
import Pattern from '../Pattern/Pattern';
import ProductSliderProduct from '../ProductSliderProduct/ProductSliderProduct';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMediaQuery } from 'react-responsive';
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc';
import { useStaticQuery, graphql } from 'gatsby';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrow arrow-right">
      <VscChevronRight onClick={onClick} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className="arrow arrow-left">
      <VscChevronLeft onClick={onClick} />
    </div>
  );
};

const ProductSlider = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const [index, setIndex] = useState(0);
  const [mobIndex, setMobIndex] = useState(0);
  const { t } = useTranslation();
  const productslider = useRef();
  const desktopSlider = useRef(null);

  const data = useStaticQuery(graphql`
    query imagesQuery {
      image1: file(relativePath: { eq: "denim_1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 294, placeholder: BLURRED)
        }
      }
      image2: file(relativePath: { eq: "skyblue_4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 294, placeholder: BLURRED)
        }
      }
      image3: file(relativePath: { eq: "blue_2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 294, placeholder: BLURRED)
        }
      }
      image4: file(relativePath: { eq: "silver_1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 294, placeholder: BLURRED)
        }
      }
      image5: file(relativePath: { eq: "lipstic_4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 294, placeholder: BLURRED)
        }
      }
      image6: file(relativePath: { eq: "superslim_3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 294, placeholder: BLURRED)
        }
      }
    }
  `);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: '40px',
    lazyLoad: true,
    beforeChange: (current, next) => setIndex(next),
    // afterChange: (current) => setNextIndex({ nextIndex: current }),
  };

  const settingsMobile = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: '0',
    lazyLoad: true,
    beforeChange: (current, next) => setMobIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // afterChange: (current) => setNextIndex({ nextIndex: current }),
  };

  const nextSlide = () => {
    desktopSlider.current.slickNext();
  };

  const prevSlide = () => {
    desktopSlider.current.slickPrev();
  };

  const products = [
    {
      id: 0,
      name: 'Denim',
      features:
        '<b>Format:</b> King Size (84 mm)<br><b>Pack:</b> Round Corner<br><b>Blend:</b> American<br><b>Filter:</b> OF Dual Combined<br><b>Cigarettes:</b> 20<br><b>',
      image: data.image1.childImageSharp.gatsbyImageData,
    },
    {
      id: 1,
      name: 'SKY BLUE',
      features:
        'Format: King Size (84 mm)<br>Pack: Round Corner<br>Blend: American<br>Filter: OF Dual Combined<br>Cigarettes: 20',
      image: data.image2.childImageSharp.gatsbyImageData,
    },
    {
      id: 2,
      name: 'BLUE',
      features:
        'Format: Super Slim (97 mm)<br>Pack: Flatpack Square Corner<br>Blend: American<br>Filter: OF Dual Combined<br>Cigarettes: 20',
      image: data.image3.childImageSharp.gatsbyImageData,
    },
    {
      id: 3,
      name: 'SILVER',
      features:
        'Format: Super Slim (97 mm)<br>Pack: Flatpack Square Corner<br>Blend: American<br>Filter: OF Dual Combined<br>Cigarettes: 20',
      image: data.image4.childImageSharp.gatsbyImageData,
    },
    {
      id: 4,
      name: 'CAPSULE',
      features: 'Format: Super Slim (97 mm)<br>Pack: Lipstick<br>Blend: American<br>Filter: Capsule<br>Cigarettes: 20',
      image: data.image5.childImageSharp.gatsbyImageData,
    },
    {
      id: 5,
      name: 'CAPSULE',
      features:
        'Format: Super Slim (97 mm)<br>Pack: Flatpack Square Corner<br>Blend: American<br>Filter: Capsule<br>Cigarettes: 20',
      image: data.image6.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <>
      {!isTabletOrMobile && (
        <ProductSliderWrapper id="products" ref={productslider}>
          <Container fluid>
            <Row>
              <Col className="slider-bg__left" md={5}></Col>
              <Col
                className={`slider-bg__right ${index === 0 ? 'slider-bg__right--denim' : ''}${
                  index === 1 ? 'slider-bg__right--sky-blue' : ''
                }${index === 2 ? 'slider-bg__right--blue' : ''}${index === 3 ? 'slider-bg__right--silver' : ''}${
                  index === 4 ? 'slider-bg__right--capsule' : ''
                }${index === 5 ? 'slider-bg__right--capsule-2' : ''}`}
                md={7}
              >
                <div className="arrows">
                  <div className="arrow right-arrow" onClick={nextSlide}>
                    <VscChevronRight />
                  </div>
                </div>
                <div className="light-radial"></div>
                <Pattern showPatterns={2} />
              </Col>
              <ProductSliderRow>
                <Container>
                  <Row className="slider-row--main">
                    <Col className="left" md={4}>
                      <div className="product-description">
                        <h3 className="title main-title text-uppercase main-title--blue">{t('products.title')}</h3>
                        <div className="content">{t('products.description')}</div>
                      </div>
                    </Col>
                    <Col
                      className={`right ${index === 0 || index === 1 ? 'right--blue' : ''}${
                        index === 2 || index === 3 ? 'right--turquoise' : ''
                      }${index === 4 || index === 5 ? 'right--lightblue' : ''}`}
                      md={8}
                    >
                      <div className="arrows">
                        <div className="arrow left-arrow" onClick={prevSlide}>
                          <VscChevronLeft />
                        </div>
                      </div>
                      <Slider {...settings} className="slider-services" ref={desktopSlider}>
                        {products.map((product) => (
                          <div key={product.id}>
                            <ProductSliderProduct
                              name={product.name}
                              description={product.features}
                              image={product.image}
                              id={product.id}
                            />
                          </div>
                        ))}
                      </Slider>
                    </Col>
                  </Row>
                </Container>
              </ProductSliderRow>
            </Row>
          </Container>
        </ProductSliderWrapper>
      )}
      {isTabletOrMobile && (
        <ProductSliderRowMobile>
          <Container fluid>
            <Row className="slider-row--main">
              <Col>
                <div className="product-description" id="products">
                  <h3 className="title main-title main-title--blue">{t('products.title')}</h3>
                  <div className="content">{t('products.description')}</div>
                </div>
              </Col>
            </Row>
            <Row
              className={`slider-bg ${mobIndex === 0 ? 'slider-bg--denim' : ''}${
                mobIndex === 1 ? 'slider-bg--sky-blue' : ''
              }${mobIndex === 2 ? 'slider-bg--blue' : ''}${mobIndex === 3 ? 'slider-bg--silver' : ''}${
                mobIndex === 4 ? 'slider-bg--capsule' : ''
              }${mobIndex === 5 ? 'slider-bg--capsule-2' : ''}`}
            >
              <div className="light-radial"></div>
              <div className="pattern-holder">
                <Pattern showPatterns={2} />
              </div>
              <Col>
                <Slider {...settingsMobile} className="slider-services">
                  {products.map((product) => (
                    <div key={product.id}>
                      <ProductSliderProduct name={product.name} description={product.features} image={product.image} />
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>
        </ProductSliderRowMobile>
      )}
    </>
  );
};

export default ProductSlider;
