import styled from 'styled-components';

export const PatternWrapper = styled.div`
  width: 100%;
  height: 100%;
  .pattern {
    width: 50%;
    height: 50%;
    position: absolute;
    .gatsby-image-wrapper {
      position: initial;
    }
    &.pattern-left {
      left: 0;
      &--bottom {
        bottom: 0;
      }
      &--top {
        top: 0;
      }
    }
    &.pattern-right {
      right: 0;
      &--bottom {
        bottom: 0;
      }
      &--top {
        top: 0;
      }
    }
  }
`;
